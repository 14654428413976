define('student/models/v1/convention-session-attachment', ['exports', 'vd-shared/models/v1/convention-session-attachment'], function (exports, _conventionSessionAttachment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conventionSessionAttachment.default;
    }
  });
});