define('student/router', ['exports', 'student/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition: function didTransition() {
      try {
        this._super.apply(this, arguments);
      } catch (error) {
        // Babel throws a Symbol is undefined error in IE11 but
        // seems like it can still continue working anyhow
        if (error.message.indexOf('Symbol') !== -1) {
          window.console && window.console.warn(error.message);
        } else {
          throw error;
        }
      }

      window.scrollTo(0, 0);

      // Once the first route is done loading, we can hide the spinner
      // NOTE: for async components, we can additionally wait until
      // the component is done.. currently using DOM checks for this
      // but could alternatively add a service
      var doneLoading = function doneLoading() {
        var loader = document.querySelector('.app-loader');
        if (loader) {
          loader.classList.remove('is-visible');
        }
        var app = Array.prototype.slice.call(document.querySelectorAll('body > .ember-view'));
        if (app) {
          app.forEach(function (item) {
            return item.classList.add('is-visible');
          });
        }
      };

      var isLoading = function isLoading() {
        var loaders = document.querySelectorAll('.vd-loader--loading');
        return loaders.length > 0;
      };

      if (isLoading()) {
        var timer = void 0;
        timer = setInterval(function () {
          if (isLoading()) {
            return;
          }

          clearInterval(timer);
          doneLoading();
        }, 100);
      } else {
        doneLoading();
      }
    }
  });

  Router.map(function () {
    this.route('institution-scope', { path: '/:subdomain' }, function () {
      // Pages that open up mobi
      this.route('evr', { path: '/evr', resetNamespace: true });
      this.route('evr-show', { path: '/evr/:id', resetNamespace: true });
      this.route('evrc', { path: '/evrc', resetNamespace: true });
      this.route('evrc-show', { path: '/evrc/:id', resetNamespace: true });
      this.route('promote', { path: '/ci/:keyword', resetNamespace: true });

      // Student app only pages
      this.route('surv', { path: '/surv/:id', resetNamespace: true });
      this.route('itinerary', {
        path: '/itineraries/:code',
        resetNamespace: true
      }, function () {
        this.route('user-visit', { path: '/user-visits/:id' });
        this.route('join-conference', {
          path: '/user-visits/:user_visit_id/join'
        });
      });
      this.route('mobi', { path: '/mobi', resetNamespace: true });
      this.route('mobi-instance', {
        path: '/mobi/instance/:id',
        resetNamespace: true
      });
      this.route('mobi-category', {
        path: '/mobi/:category',
        resetNamespace: true
      });

      this.route('not-found', { path: '/not-found', resetNamespace: true });
    });
  });

  exports.default = Router;
});