define('student/models/admin-user', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    initials: (0, _attr.default)('string'),
    avatarSmall: (0, _attr.default)('string'),
    bio: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    email: (0, _attr.default)('string')
  });
});