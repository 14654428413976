define('student/components/vd-button-link', ['exports', 'vd-shared/components/vd-button-link'], function (exports, _vdButtonLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vdButtonLink.default;
    }
  });
});