define('student/models/visit', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    image: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    timeZone: (0, _attr.default)('string'),
    capacity: (0, _attr.default)('number'),
    conferenceUrl: (0, _attr.default)('string'),
    zoomAudioNumber: (0, _attr.default)('string'),
    integratedMeetingType: (0, _attr.default)('string'),
    integratedMeetingUrl: (0, _attr.default)('string'),
    startTime: (0, _attr.default)('date'),
    endTime: (0, _attr.default)('date'),
    maxGuests: (0, _attr.default)('number'),
    maxRegistrations: (0, _attr.default)('number'),
    numPeople: (0, _attr.default)('number'),
    numStudents: (0, _attr.default)('number'),
    numCheckedIn: (0, _attr.default)('number'),
    textId: (0, _attr.default)('number'),
    type: (0, _attr.default)('string', { defaultValue: 'InstantBookVisit' }),
    hideAdmins: (0, _attr.default)('boolean'),

    location: (0, _relationships.belongsTo)('location', { async: false }),
    visitType: (0, _relationships.belongsTo)('visitType', { async: false }),

    adminUsers: (0, _relationships.hasMany)('adminUser'),
    teams: (0, _relationships.hasMany)('team'),
    userVisits: (0, _relationships.hasMany)('userVisit', { async: false, inverse: 'visit' }),
    groupUserVisits: (0, _relationships.hasMany)('userVisit', { async: false, inverse: 'visit' }),

    isRequest: Ember.computed.equal('type', 'RequestVisit'),

    isZoomMeeting: Ember.computed.equal('integratedMeetingType', 'zoom'),
    isIntegratedMeeting: Ember.computed.equal('integratedMeetingType', 'integrated'),
    isVirtualMeeting: Ember.computed.not('integratedMeetingType', 'meeting_type_not_set')
  });
});