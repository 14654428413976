define('student/models/v1/convention-assignee', ['exports', 'vd-shared/models/v1/convention-assignee'], function (exports, _conventionAssignee) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conventionAssignee.default;
    }
  });
});