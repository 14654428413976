define('student/services/current-institution-service', ['exports', 'vd-shared/services/current-institution-service'], function (exports, _currentInstitutionService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _currentInstitutionService.default;
    }
  });
});