define('student/models/institution-user', ['exports', 'ember-data/attr', 'student/models/visitor', 'ember-data/relationships'], function (exports, _attr, _visitor, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _visitor.default.extend({
    firstName: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),

    visits: (0, _relationships.hasMany)('visit'),
    questionResponses: (0, _relationships.hasMany)('questionResponse', { async: false })
  });
});