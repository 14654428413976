define('student/helpers/markdown-html', ['exports', 'ember-cli-markdown-it/helpers/markdown-html'], function (exports, _markdownHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _markdownHtml.default;
    }
  });
  Object.defineProperty(exports, 'markdownHtml', {
    enumerable: true,
    get: function () {
      return _markdownHtml.markdownHtml;
    }
  });
});