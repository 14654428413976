define('student/instance-initializers/airbrake-reporter', ['exports', 'npm:airbrake-js', 'student/config/environment'], function (exports, _npmAirbrakeJs, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    if (_environment.default.airbrake && _environment.default.airbrake.projectId) {
      var airbrake = new _npmAirbrakeJs.default({
        projectId: _environment.default.airbrake.projectId,
        projectKey: _environment.default.airbrake.projectKey,
        environment: _environment.default.deployTarget
      });

      var notify = function notify(error) {
        // Ember has a bug where when we do a this.transitionTo from a route, a
        // TransitionAborted error is being thrown.
        //
        // Since we are using this behaviour in index-html route, we don't want this
        // particular error to be tracked at all.
        //
        // https://github.com/emberjs/ember.js/issues/12505
        if (error.name !== 'TransitionAborted') {
          console.error(error);
          airbrake.notify(error);
        }
      };

      // Track ember errors, preserving any global onerror handler
      var onerror = window.onerror || function () {};
      Ember.onerror = function (error) {
        onerror(error);
        notify(error);
      };

      // Track RSVP errors
      Ember.RSVP.on('error', notify);
    }
  }

  exports.default = {
    name: 'airbrake-reporter',
    initialize: initialize
  };
});