define('student/services/themes', ['exports', 'vd-shared/services/themes'], function (exports, _themes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _themes.default;
    }
  });
});