define('student/components/vd-switch', ['exports', 'vd-shared/components/vd-switch'], function (exports, _vdSwitch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vdSwitch.default;
    }
  });
});