define('student/mobi/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentInstitution: Ember.inject.service(),
    institution: Ember.computed.alias('currentInstitution.institution')
  });
});