define('student/components/student-user-visit-conference-integrated-meeting/component', ['exports', 'vd-shared/mixins/tracks-time'], function (exports, _tracksTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tracksTime.default, {
    tagName: 'div',
    classNames: ['student-user-visit-conference-integrated-meeting'],

    institution: undefined,
    userVisit: undefined,

    redirectTime: undefined,

    visit: Ember.computed.alias('userVisit.visit').readOnly(),

    //provided as a way to expand upon known good embeddable URIs
    embeddable: Ember.computed('youTubeID', {
      get: function get() {
        return false;

        // const youTubeID = get(this, 'youTubeID');
        // return !!youTubeID;
      },
      set: function set(_key, value) {
        var youTubeID = Ember.get(this, 'youTubeID');
        if (!youTubeID) {
          var redirectTime = moment(new Date()).add(15, 'second');
          Ember.set(this, 'redirectTime', redirectTime);
        }
        return value;
      }
    }),

    youTubeID: Ember.computed('visit.integratedMeetingUrl', function () {
      var integratedMeetingUrl = Ember.get(this, 'visit.integratedMeetingUrl');
      if (integratedMeetingUrl) {
        var pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        var matches = integratedMeetingUrl.match(pattern);
        if (matches) {
          return matches[1];
        } else {
          return null;
        }
      }
      return null;
    }),

    youTubePlayerVars: Ember.computed(function () {
      return {
        // Setting an origin can remove a YouTube 'postMessage' API warning in the console.
        // Note, this does not have any effect on localhost.
        origin: window.location.protocol + '//' + window.location.host
      };
    }),

    didInsertElement: function didInsertElement() {
      /*
        RTFM moment - In cases where you have components nested inside other components, the child
        component will always receive the didInsertElement() call before its parent does.
        so in our case student-user-visit-conference/component.js does the pingCheckIn() call
        but if they join when the session is in progress
        student-user-visit-conference-integrated-meeting/component.js will receive the didInsertElement
        event first, so use setTimeout with 0 so it executes after everything is rendered.
        The lifecycle is fine, it's the redirect that's hacky.. there's no concern here that
        the lifecycle of components will take multiple frames (that's probably never a thing)
      */
      var redirectUrl = Ember.get(this, 'visit.integratedMeetingUrl');
      setTimeout(function () {
        if (window.location.replace) {
          window.location.replace(redirectUrl);
        } else {
          window.location.href = redirectUrl;
        }
      }, 0);
    }
  });
});