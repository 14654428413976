define('student/models/v1/convention-providers-registrant-type', ['exports', 'vd-shared/models/v1/convention-providers-registrant-type'], function (exports, _conventionProvidersRegistrantType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conventionProvidersRegistrantType.default;
    }
  });
});