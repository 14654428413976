define('student/models/v1/mobi-discovery-filter-question', ['exports', 'vd-shared/models/v1/mobi-discovery-filter-question'], function (exports, _mobiDiscoveryFilterQuestion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mobiDiscoveryFilterQuestion.default;
    }
  });
});