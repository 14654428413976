define('student/itinerary/user-visit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ItineraryUserVisitController = Ember.Controller.extend({
    itineraryController: Ember.inject.controller('itinerary'),

    institution: Ember.computed.readOnly('itineraryController.institution'),
    itinerary: Ember.computed.readOnly('itineraryController.itinerary'),
    userVisits: Ember.computed.readOnly('itineraryController.userVisits'),

    userVisitId: Ember.computed.readOnly('model.id'),

    currentUserVisit: Ember.computed('userVisits', 'userVisitId', {
      get: function get() {
        var _this = this;

        return Ember.get(this, 'userVisits').find(function (userVisit) {
          return Ember.get(userVisit, 'id') === Ember.get(_this, 'userVisitId');
        });
      }
    }).readOnly(),

    nextUserVisit: Ember.computed('userVisits.[]', 'currentUserVisit', {
      get: function get() {
        var currentUserVisit = Ember.get(this, 'currentUserVisit');
        var userVisits = Ember.get(this, 'userVisits');
        var index = userVisits.indexOf(currentUserVisit);
        if (index !== -1) {
          return userVisits[index + 1];
        }
      }
    }).readOnly(),

    previousUserVisit: Ember.computed('userVisits.[]', 'currentUserVisit', {
      get: function get() {
        var currentUserVisit = Ember.get(this, 'currentUserVisit');
        var userVisits = Ember.get(this, 'userVisits');
        var index = userVisits.indexOf(currentUserVisit);
        if (index !== -1) {
          return userVisits[index - 1];
        }
      }
    }).readOnly()
  });

  exports.default = ItineraryUserVisitController;
});