define('student/promote/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentInstitution: Ember.inject.service(),

    queryParams: ['audience', 'category', 'eventTypes', 'eventId'],

    institution: Ember.computed.alias('currentInstitution.institution')
  });
});