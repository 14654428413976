define('student/services/v1-api-service', ['exports', 'vd-shared/services/v1-api-service'], function (exports, _v1ApiService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _v1ApiService.default;
    }
  });
});