define('student/instance-initializers/google-analytics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var googleAnalytics = application.lookup('service:googleAnalytics');
    googleAnalytics.init();

    var router = application.lookup('router:main');
    router.on('didTransition', function () {
      var location = Ember.get(this, 'location.location.href');
      var path = Ember.get(this, 'url');

      // The ember-cli-document-title addon doesn't seem to provide a way
      // to know when the title is set, and it's also not using an initializer
      // so there's no way to ensure running code after it in order to
      // hook in. It sets it during the `didTransition` event but it adds a
      // vendor js file to the final output so most likely will come
      // after this file itself. The best approach seems to be a timeout.
      // Technically there's a margin of error here between when didTransition
      // is fired and the next timeout would run, but I believe a timeout 0 is
      // typically added to the end of the callstack so js could trigger
      // something but a user couldn't, and the chances are infinitely slim.
      // If this is not done, every page will have a title of 'VisitDays'
      // so the slight chance of a wrong title seems better than
      // not having any title usage at all.
      setTimeout(function () {
        var title = window.document.title;
        googleAnalytics.addPageView({ location: location, path: path, title: title });
      }, 0);
    });
  }

  exports.default = {
    initialize: initialize
  };
});