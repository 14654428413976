define('student/adapters/survey-question', ['exports', 'vd-shared/adapters/survey-question'], function (exports, _surveyQuestion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _surveyQuestion.default;
    }
  });
});