define('student/models/question-response', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    text: (0, _attr.default)('string'),

    itinerary: (0, _relationships.belongsTo)('itinerary'),
    question: (0, _relationships.belongsTo)('question'),
    questionOption: (0, _relationships.belongsTo)('questionOption'),
    source: (0, _relationships.belongsTo)('source', { polymorphic: true })
  });
});