define('student/components/vd-form-field-sub-label', ['exports', 'vd-shared/components/vd-form-field-sub-label'], function (exports, _vdFormFieldSubLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vdFormFieldSubLabel.default;
    }
  });
});