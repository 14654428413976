define('student/components/mobi-script/component', ['exports', 'student/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mobiScript: Ember.computed('visitId', 'subdomain', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'subdomain', 'visitId', 'checkIn', 'showButton', 'keyword', 'audience', 'category', 'eventTypes', 'instanceId'),
          subdomain = _EmberGetProperties.subdomain,
          visitId = _EmberGetProperties.visitId,
          checkIn = _EmberGetProperties.checkIn,
          showButton = _EmberGetProperties.showButton,
          keyword = _EmberGetProperties.keyword,
          audience = _EmberGetProperties.audience,
          category = _EmberGetProperties.category,
          eventTypes = _EmberGetProperties.eventTypes,
          instanceId = _EmberGetProperties.instanceId;

      var snippetURL = _environment.default.mobi.snippetURL;
      if (!subdomain && !instanceId) {
        return null;
      }

      var script = document.createElement('script');
      script.setAttribute('src', snippetURL);
      if (subdomain) {
        script.setAttribute('data-mobi-subdomain', subdomain);
      }
      script.setAttribute('async', true);

      if (!showButton) {
        script.setAttribute('data-mobi-load-state', 'show');
        script.setAttribute('data-mobi-hide-default-button', '');
      }
      if (checkIn) {
        script.setAttribute('data-mobi-checkin', '');
      }
      if (keyword) {
        script.setAttribute('data-mobi-keyword', keyword);
      }
      if (category) {
        script.setAttribute('data-mobi-categories', category);
      }
      if (audience) {
        script.setAttribute('data-mobi-audiences', audience);
      }
      // This a comma separated list of visit type ids, Eg: "123,324"
      if (eventTypes) {
        script.setAttribute('data-mobi-event-types', eventTypes);
      }
      if (visitId) {
        script.setAttribute('data-mobi-event', visitId);
      }
      if (instanceId) {
        script.setAttribute('data-mobi-id', instanceId);
      }

      document.body.appendChild(script);

      return '';
    })
  });
});