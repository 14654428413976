define('student/mobi-category/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var category = _ref.category;

      return Ember.RSVP.hash({ category: category, audience: 5 });
    }
  });
});