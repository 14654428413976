define('student/components/app-itinerary-user-visit/component', ['exports', 'vd-shared/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppItineraryUserVisit = Ember.Component.extend({
    tagName: 'div',
    classNames: ['app-itinerary-user-visit'],
    attributeBindings: ['style'],

    userVisit: undefined,

    visit: Ember.computed.readOnly('userVisit.visit'),
    visitType: Ember.computed.readOnly('visit.visitType'),

    name: Ember.computed.readOnly('visitType.name'),
    description: Ember.computed.readOnly('visitType.description'),
    startTime: Ember.computed.readOnly('visit.startTime'),
    endTime: Ember.computed.readOnly('visit.endTime'),
    assignees: Ember.computed('visit.adminUsers.[]', 'visit.teams.[]', {
      get: function get() {
        var adminUsers = Ember.get(this, 'visit.adminUsers');
        var teams = Ember.get(this, 'visit.teams');
        return [].concat(teams.toArray(), adminUsers.toArray());
      }
    }).readOnly(),
    shouldShowAssignees: Ember.computed('assignees', 'visit.hideAdmins', {
      get: function get() {
        var assignees = Ember.get(this, 'assignees');
        var hideAdmins = Ember.get(this, 'visit.hideAdmins');
        return !hideAdmins && assignees.length > 0;
      }
    }).readOnly(),

    channelType: Ember.computed.readOnly('userVisit.channelType'),
    isInPerson: Ember.computed.equal('channelType', 'in_person').readOnly(),
    channelInstructions: Ember.computed.readOnly('userVisit.channelInstructions'),

    location: Ember.computed('isInPerson', 'visit.location', {
      get: function get() {
        var isInPerson = Ember.get(this, 'isInPerson');
        var location = Ember.get(this, 'visit.location');
        return isInPerson && location || undefined;
      }
    }).readOnly(),

    meetingInstructions: Ember.computed('isInPerson', 'location.meetingLocation', {
      get: function get() {
        var isInPerson = Ember.get(this, 'isInPerson');
        var meetingLocation = Ember.get(this, 'location.meetingLocation');
        return isInPerson ? meetingLocation : undefined;
      }
    }).readOnly(),

    parkingInstructions: Ember.computed('isInPerson', 'location.parkingLocation', {
      get: function get() {
        var isInPerson = Ember.get(this, 'isInPerson');
        var parkingLocation = Ember.get(this, 'location.parkingLocation');
        return isInPerson && parkingLocation || undefined;
      }
    }).readOnly(),

    miscInstructions: Ember.computed('isInPerson', 'channelInstructions', {
      get: function get() {
        var isInPerson = Ember.get(this, 'isInPerson');
        var channelInstructions = Ember.get(this, 'channelInstructions');
        if (isInPerson) {
          return undefined;
        }
        return channelInstructions;
      }
    }),

    miscInstructionsName: Ember.computed('isInPerson', 'channelType', {
      get: function get() {
        var isInPerson = Ember.get(this, 'isInPerson');
        var channelType = Ember.get(this, 'channelType');
        if (isInPerson) {
          return undefined;
        }
        if (channelType === 'online') {
          return 'Online Instructions';
        } else if (channelType === 'phone') {
          return 'Phone Instructions';
        } else {
          return 'Instructions';
        }
      }
    }).readOnly(),

    mapUrl: Ember.computed('location.latitude', 'location.longitude', {
      get: function get() {
        var latitude = Ember.get(this, 'location.latitude');
        var longitude = Ember.get(this, 'location.longitude');
        if (!latitude || !longitude) {
          return undefined;
        }

        var width = 528; // max width at highest resolution
        var height = width * 0.5625; // 16:9 aspect ratio
        // TODO: Make this reusable in some fashion... key should be part of config
        return 'https://maps.googleapis.com/maps/api/staticmap?center=' + latitude + ',' + longitude + '&zoom=15&size=' + width + 'x' + height + '&maptype=roadmap&markers=' + latitude + ',' + longitude + '&key=AIzaSyAgVT8SsJShfNk4NLrirRft1nOZxQcPiW8';
      }
    }).readOnly(),

    viewMapUrl: Ember.computed('location', {
      get: function get() {
        var location = Ember.get(this, 'location');
        if (!location) {
          return;
        }

        return (0, _util.getGoogleMapsSearchUrl)({
          lines: [Ember.get(location, 'name'), Ember.get(location, 'street'), Ember.get(location, 'street2'), Ember.get(location, 'street3')],
          city: Ember.get(location, 'city'),
          region: Ember.get(location, 'state'),
          postalCode: Ember.get(location, 'zipcodeDisplay')
        });
      }
    }).readOnly(),

    // https://visitdays.github.io/vd-shared/versions/master/docs/embeds
    supportingAttachments: Ember.computed('visit.visitType.supportingAttachments', {
      get: function get() {
        var supportingAttachments = Ember.get(this, 'visit.visitType.supportingAttachments');
        return supportingAttachments.map(function (attachment) {
          return {
            url: Ember.get(attachment, 'url'),
            caption: Ember.get(attachment, 'caption'),
            embedInfo: (0, _util.getEmbedInfo)(Ember.get(attachment, 'url'))
          };
        });
      }
    }).readOnly()
  });

  AppItineraryUserVisit.reopenClass({
    positionalParams: ['userVisit']
  });

  exports.default = AppItineraryUserVisit;
});