define('student/services/get-institution-by-subdomain', ['exports', 'vd-shared/services/get-institution-by-subdomain'], function (exports, _getInstitutionBySubdomain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getInstitutionBySubdomain.default;
    }
  });
});