define('student/services/color-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LIGHT_COLOR = '#ffffff';
  var DARK_COLOR = '#444c50';

  exports.default = Ember.Service.extend({
    getContrastColor: function getContrastColor(color) {
      if (color[0] !== '#' || color.length !== 7) {
        console.warn('Invalid color passed to ColorService#getContrastColor, got ' + color);
        return undefined;
      }

      var red = parseInt(color.substr(1, 2), 16);
      var green = parseInt(color.substr(3, 2), 16);
      var blue = parseInt(color.substr(5, 2), 16);

      // Use the YIQ formula for Y to determine the contrast of the color.
      // YIQ weights colors based on how sensitive the human eye is to each
      // color, Y stands for `luma` and represents the contrast as used
      // in B&W images
      var luma = (red * 299 + green * 587 + blue * 114) / 1000;
      return luma >= 165 ? DARK_COLOR : LIGHT_COLOR;
    }
  });
});