define('student/adapters/survey-question-option', ['exports', 'vd-shared/adapters/survey-question-option'], function (exports, _surveyQuestionOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _surveyQuestionOption.default;
    }
  });
});