define('student/services/get-all-models', ['exports', 'vd-shared/services/get-all-models'], function (exports, _getAllModels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getAllModels.default;
    }
  });
});