define('student/models/v1/mobi-discovery-filter-option-visit-type', ['exports', 'vd-shared/models/v1/mobi-discovery-filter-option-visit-type'], function (exports, _mobiDiscoveryFilterOptionVisitType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mobiDiscoveryFilterOptionVisitType.default;
    }
  });
});