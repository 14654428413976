define('student/components/app-itinerary-subheading/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppItinerarySubheading = Ember.Component.extend({
    tagName: 'div',
    classNames: ['app-itinerary-subheading'],
    attributeBindings: ['style'],
    layout: Ember.HTMLBars.template({
      "id": "t5Jg0Pkx",
      "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[20,[\"has-block\"]]],null,{\"statements\":[[0,\"      \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[18,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });

  AppItinerarySubheading.reopenClass({
    positionalParams: ['text']
  });

  exports.default = AppItinerarySubheading;
});