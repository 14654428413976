define('student/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    applicationAdapter: Ember.computed({
      get: function get() {
        return Ember.getOwner(this).lookup('adapter:application');
      }
    }),

    host: Ember.computed.readOnly('applicationAdapter.host'),
    namespace: Ember.computed.readOnly('applicationAdapter.namespace'),
    headers: Ember.computed.readOnly('applicationAdapter.headers')
  });
});