define('student/services/google-analytics', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    id: Ember.computed.readOnly('config.id'),

    config: Ember.computed({
      get: function get() {
        return _emberGetConfig.default.googleAnalytics || {};
      }
    }),

    init: function init() {
      if (Ember.get(this, 'initialized')) {
        return;
      }

      var id = Ember.get(this, 'id');

      // Add initial tags, gtag will pick these up once it loads
      this.gtag('js', new Date());
      this.gtag('config', id, {
        // Don't record the first page twice, router will add it
        send_page_view: false
      });

      // Only add the script if there's an ID configured; however,
      // continue to otherwise function as normal to facilitate testing
      if (id) {
        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
        document.body.appendChild(script);
      }

      Ember.set(this, 'initialized', true);

      return this._super.apply(this, arguments);
    },
    gtag: function gtag() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(arguments);
    },
    addPageView: function addPageView(_ref) {
      var title = _ref.title,
          location = _ref.location,
          path = _ref.path;

      var id = Ember.get(this, 'id');
      return this.gtag('config', id, {
        page_title: title,
        page_location: location,
        page_path: path
      });
    }
  });
});