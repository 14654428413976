define('student/components/student-user-visit-conference-zoom/component', ['exports', 'vd-shared/mixins/tracks-time'], function (exports, _tracksTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tracksTime.default, {
    tagName: 'div',
    classNames: ['student-user-visit-conference-zoom'],

    institution: undefined,
    userVisit: undefined
  });
});