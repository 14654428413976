define('student/models/v1/nylas-background-job', ['exports', 'vd-shared/models/v1/nylas-background-job'], function (exports, _nylasBackgroundJob) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _nylasBackgroundJob.default;
    }
  });
});