define('student/components/app-itinerary-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppItinerarySummary = Ember.Component.extend({
    tagName: 'div',
    classNames: ['app-itinerary-summary'],
    attributeBindings: ['style'],

    institution: undefined,
    itinerary: undefined,
    userVisits: undefined,

    openCancelRegistrationModal: undefined,

    // Most properties are based on the FIRST user visit
    // and students need to access individual details for others
    channelType: Ember.computed.readOnly('firstUserVisit.channelType'),
    checkInType: Ember.computed.readOnly('firstVisitType.checkInType'),
    conferenceUrl: Ember.computed.readOnly('firstUserVisit.conferenceUrl'),
    firstVisit: Ember.computed.readOnly('firstUserVisit.visit'),
    firstVisitType: Ember.computed.readOnly('firstVisit.visitType'),
    firstVisitIntegratedMeetingUrl: Ember.computed.readOnly('firstVisit.integratedMeetingUrl'),
    hasContactInformation: Ember.computed.or('institutionEmail', 'institutionPhone'),
    hasSocialMedia: Ember.computed.or('institutionFacebookHandle', 'institutionTwitterHandle'),
    institutionEmail: Ember.computed.readOnly('institution.admissionsEmail'),
    institutionFacebookHandle: Ember.computed.readOnly('institution.facebookHandle'),
    institutionPhone: Ember.computed.readOnly('institution.phone'),
    institutionTwitterHandle: Ember.computed.readOnly('institution.twitterHandle'),
    isActive: Ember.computed.not('itinerary.isCanceled'),
    isAutomatedCheckin: Ember.computed.equal('checkInType', 'automated'),
    isOnline: Ember.computed.equal('channelType', 'online'),

    firstUserVisit: Ember.computed('userVisits.[]', {
      get: function get() {
        var userVisits = Ember.get(this, 'userVisits');
        if (userVisits) {
          return userVisits[0];
        }
      }
    }).readOnly()
  });

  exports.default = AppItinerarySummary;
});