define('student/components/vd-text-field/component', ['exports', '@material/textfield'], function (exports, _textfield) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['vd-text-field'],

    name: null,
    value: null,
    // arbitrary value, Material requires it set
    maxLength: 10000,

    id: Ember.computed('name', function () {
      var id = Ember.get(this, 'name');
      id = id.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      id = id.replace(/^-+/, '').replace(/-+$/, '');
      return 'vd-text-field-' + id;
    }),

    didInsertElement: function didInsertElement() {
      var textField = new _textfield.MDCTextField(this.$('.mdc-text-field')[0]);
      this.mdcTextField = textField;
    },


    actions: {
      onChange: function onChange(el) {
        if (Ember.get(this, 'onTextInput')) {
          Ember.get(this, 'onTextInput')(el.target.value);
        }
      }
    }
  });
});