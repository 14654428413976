define('student/itinerary/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ItineraryIndexController = Ember.Controller.extend({
    itineraryController: Ember.inject.controller('itinerary'),

    institution: Ember.computed.readOnly('itineraryController.institution'),
    itinerary: Ember.computed.readOnly('itineraryController.itinerary'),
    userVisits: Ember.computed.readOnly('itineraryController.userVisits'),

    openCancelRegistrationModal: function openCancelRegistrationModal() {
      var itineraryController = Ember.get(this, 'itineraryController');
      return itineraryController.cancelRegistrationClicked();
    }
  });

  exports.default = ItineraryIndexController;
});