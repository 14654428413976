define('student/itinerary/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ItineraryController = Ember.Controller.extend({
    currentInstitution: Ember.inject.service(),
    ajax: Ember.inject.service(),

    openCancelModal: false,
    userVisitIdsToCancel: null,

    institution: Ember.computed.readOnly('currentInstitution.institution'),
    itinerary: Ember.computed.alias('model.itinerary'),

    // Need to recompute on status change to handle removing
    // user visits when they're canceled
    // If other properties need to change, need to add those too
    userVisits: Ember.computed('itinerary.userVisits.@each.status', {
      get: function get() {
        var allUserVisits = Ember.get(this, 'itinerary.userVisits').toArray();
        var activeUserVisits = allUserVisits.filter(function (userVisit) {
          var status = Ember.get(userVisit, 'status');
          return status === 'registered' || status === 'checked_in' || status === 'no_show' || status === 'request_pending';
        });
        var activeRequests = [];
        var activeOthers = [];
        activeUserVisits.forEach(function (userVisit) {
          if (Ember.get(userVisit, 'isRequest')) {
            activeRequests.push(userVisit);
          } else {
            activeOthers.push(userVisit);
          }
        });
        return [].concat(activeRequests.sort(function (userVisitA, userVisitB) {
          var nameA = Ember.get(userVisitA, 'visit.visitType.name') || '';
          var nameB = Ember.get(userVisitB, 'visit.visitType.name') || '';
          return nameA.localeCompare(nameB);
        }), activeOthers.sort(function (userVisitA, userVisitB) {
          var startTimeA = Ember.get(userVisitA, 'visit.startTime');
          var startTimeB = Ember.get(userVisitB, 'visit.startTime');
          return startTimeA.getTime() - startTimeB.getTime();
        }));
      }
    }),

    actions: {
      toggleCanceledRegistration: function toggleCanceledRegistration(userVisit) {
        var userVisitIdsToCancel = Ember.get(this, 'userVisitIdsToCancel');
        var userVisitId = Ember.get(userVisit, 'id');
        if (userVisitIdsToCancel && userVisitIdsToCancel.contains) {
          if (userVisitIdsToCancel.contains(userVisitId)) {
            userVisitIdsToCancel.removeObject(userVisitId);
          } else {
            userVisitIdsToCancel.addObject(userVisitId);
          }
        } else {
          Ember.set(this, 'userVisitIdsToCancel', Ember.A([userVisitId]));
        }
      },

      cancelVisits: function cancelVisits() {
        var _this = this;

        var userVisitIdsToCancel = Ember.get(this, 'userVisitIdsToCancel');
        if (userVisitIdsToCancel && Ember.get(userVisitIdsToCancel, 'length') > 0) {
          var ajax = Ember.get(this, 'ajax');

          var _EmberGetProperties = Ember.getProperties(ajax, 'host', 'headers'),
              host = _EmberGetProperties.host,
              headers = _EmberGetProperties.headers;

          var itineraryId = Ember.get(this, 'itinerary.id');
          var subdomain = window.location.pathname.split('/')[1];
          var url = host + '/student/' + subdomain + '/itineraries/' + itineraryId + '/cancel';
          var promise = ajax.request(url, {
            method: 'POST',
            data: { user_visit_ids: userVisitIdsToCancel },
            headers: headers
          });
          promise.then(function () {
            Ember.set(_this, 'openCancelModal', false);

            // TODO: Find a way to reload the route instead to use
            // its loading state or else need a custom loading state
            Ember.get(_this, 'itinerary').reload();
          }).catch(function (error) {
            Ember.set(_this, 'error', error);
          });
        } else {
          Ember.set(this, 'error', 'There are no visits selected to cancel.');
        }
      }
    },

    cancelRegistrationClicked: function cancelRegistrationClicked() {
      Ember.set(this, 'openCancelModal', true);
      Ember.set(this, 'userVisitIdsToCancel', Ember.A());
    }
  });

  exports.default = ItineraryController;
});