define('student/components/app-itinerary-container/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppItineraryContainer = Ember.Component.extend({
    tagName: 'div',
    classNames: ['app-itinerary-container'],
    attributeBindings: ['style']
  });

  exports.default = AppItineraryContainer;
});