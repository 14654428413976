define('student/models/visit-type', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.DEFAULT_NEXT_DATE_FORMAT = undefined;
  var DEFAULT_NEXT_DATE_FORMAT = exports.DEFAULT_NEXT_DATE_FORMAT = 'dddd, MMM D';

  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    image: (0, _attr.default)('string'),
    nextAvailableDate: (0, _attr.default)('string'),
    category: (0, _attr.default)('string'),
    checkInType: (0, _attr.default)('string'),

    visits: (0, _relationships.hasMany)('visit'),
    supportingAttachments: (0, _relationships.hasMany)('supportingAttachment', { async: false }),

    // deprecated - not sure if this is used
    location: Ember.computed.readOnly('visits.firstObject.location')
  });
});