define('student/models/institution', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var Institution = Model.extend({
    name: attr('string'),
    subdomain: attr('string'),
    abbreviation: attr('string'),
    logoUrl: attr('string'),

    address1: attr('string'),
    address2: attr('string'),
    city: attr('string'),
    region: attr('string'),
    postalCode: attr('string'),
    country: attr('string'),
    timeZone: attr('string'),

    email: attr('string'),
    phone: attr('string'),
    url: attr('string'),

    admissionsEmail: attr('string'),
    admissionsUrl: attr('string'),

    facebookHandle: attr('string'),
    twitterHandle: attr('string'),

    isGradSchool: attr('boolean'),
    primaryColor: attr('string'),
    secondaryColor: attr('string'),

    // array of PhoneNumber: { friendly, e164 }
    smsNumbers: attr(),

    randomSmsNumber: Ember.computed('smsNumbers.[]', function () {
      // randomizes the smsNumber returned for a simple load balance strategy
      // will only get computed once unless more numbers are loaded in
      // or the model itself is reloaded, which should work for consistency
      // within a given session
      var smsNumbers = Ember.get(this, 'smsNumbers');
      if (smsNumbers.length) {
        return smsNumbers[Math.floor(Math.random() * smsNumbers.length)];
      }
      return null;
    })
  });

  exports.default = Institution;
});