define('student/components/vd-survey-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var questionTypesWithOptions = ['single_select', 'multi_select', 'likely_to_apply', 'likely_to_enroll'];

  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['vd-survey-question'],
    classNameBindings: ['isRequired:vd-survey-question--required:vd-survey-question--optional'],

    question: null,
    onAnswered: null,

    hasOptions: Ember.computed('question.questionType', function () {
      var questionType = Ember.get(this, 'question.questionType');
      return questionTypesWithOptions.includes(questionType);
    }),

    isRequired: Ember.computed('question.required', function () {
      var required = Ember.get(this, 'question.required');
      return required;
    }),

    actions: {
      onAnswered: function onAnswered(question, response) {
        // response will be an event for text questions
        if (response.target) {
          response = response.target.value;
        }
        var onAnswered = Ember.get(this, 'onAnswered');
        if (onAnswered) {
          onAnswered(question, response);
        }
      }
    }
  });
});