define('student/models/v1/convention-providers-convention-checkin', ['exports', 'vd-shared/models/v1/convention-providers-convention-checkin'], function (exports, _conventionProvidersConventionCheckin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conventionProvidersConventionCheckin.default;
    }
  });
});