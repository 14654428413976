define('student/models/supporting-attachment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    url: (0, _attr.default)('string'),
    caption: (0, _attr.default)('string'),

    visitType: (0, _relationships.belongsTo)('visitType')
  });
});