define('student/components/vd-button-link-to', ['exports', 'vd-shared/components/vd-button-link-to'], function (exports, _vdButtonLinkTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vdButtonLinkTo.default;
    }
  });
});