define('student/components/app-itinerary-visit-time/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppItineraryVisitTime = Ember.Component.extend({
    tagName: '',

    visit: undefined,

    isRequest: Ember.computed.readOnly('visit.isRequest'),
    startTime: Ember.computed.readOnly('visit.startTime'),
    endTime: Ember.computed.readOnly('visit.endTime'),
    timeZone: Ember.computed.readOnly('visit.timeZone')
  });

  AppItineraryVisitTime.reopenClass({
    positionalParams: ['visit']
  });

  exports.default = AppItineraryVisitTime;
});