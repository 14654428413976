define('student/promote/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var keyword = _ref.keyword,
          audience = _ref.audience,
          category = _ref.category,
          eventTypes = _ref.eventTypes,
          eventId = _ref.eventId;

      return Ember.RSVP.hash({ keyword: keyword, audience: audience, category: category, eventTypes: eventTypes, eventId: eventId });
    }
  });
});