define('student/evrc-show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    currentInstitution: Ember.inject.service(),
    institution: Ember.computed.alias('currentInstitution.institution'),

    model: function model(_ref) {
      var id = _ref.id;

      return Ember.RSVP.hash({ id: id });
    }
  });
});