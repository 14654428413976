define('student/models/v1/mobi-discovery-filter-option', ['exports', 'vd-shared/models/v1/mobi-discovery-filter-option'], function (exports, _mobiDiscoveryFilterOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mobiDiscoveryFilterOption.default;
    }
  });
});