define('student/models/v1/admin-user', ['exports', 'vd-shared/models/v1/admin-user'], function (exports, _adminUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _adminUser.default;
    }
  });
});