define('student/components/app-itinerary-user-visit-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppItineraryUserVisitHeader = Ember.Component.extend({
    tagName: 'div',
    classNames: ['app-itinerary-user-visit-header'],
    attributeBindings: ['style'],

    itinerary: undefined,
    previousUserVisit: undefined,
    nextUserVisit: undefined,
    backToUserVisit: undefined
  });

  exports.default = AppItineraryUserVisitHeader;
});