define('student/models/itinerary', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'student/utils/sort-by-time'], function (exports, _model, _attr, _relationships, _sortByTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    code: (0, _attr.default)('string'),
    visitDate: (0, _attr.default)('date'),
    numStudents: (0, _attr.default)('number'),
    numGuests: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    completelyCompletedAt: (0, _attr.default)('date'),

    visitor: (0, _relationships.belongsTo)('visitor', { polymorphic: true, async: false }),

    questionResponses: (0, _relationships.hasMany)('questionResponse', { async: false }),
    userVisits: (0, _relationships.hasMany)('userVisit', { async: false }),
    surveyQuestions: (0, _relationships.hasMany)('question', { polymorphic: true }),

    sortedUserVisits: Ember.computed.sort('userVisits', (0, _sortByTime.default)('startTime')),

    completelyCompleted: Ember.computed('completelyCompletedAt', function () {
      return !!Ember.get(this, 'completelyCompletedAt');
    }),

    isRegistered: Ember.computed('status', function () {
      var status = Ember.get(this, 'status');
      return status === 'itinerary_registered' || status === 'itinerary_checked_in' || status === 'itinerary_survey_completed';
    }),

    isCanceled: Ember.computed('status', function () {
      var status = Ember.get(this, 'status');
      return status === 'itinerary_canceled' || status === 'itinerary_rescheduled' || status == 'itinerary_request_expired';
    })
  });
});