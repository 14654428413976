define('student/models/user-visit', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    channelInstructions: (0, _attr.default)('string'),
    channelType: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    conferenceUrl: (0, _attr.default)('string'),

    itinerary: (0, _relationships.belongsTo)('itinerary', { async: false }),
    visit: (0, _relationships.belongsTo)('visit', { async: false, inverse: 'userVisits' })
  });
});