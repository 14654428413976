define('student/helpers/vd-style', ['exports', 'vd-shared/helpers/vd-style'], function (exports, _vdStyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vdStyle.default;
    }
  });
});