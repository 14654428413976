define('student/models/question-option', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    text: _emberData.default.attr('string'),
    value: _emberData.default.attr(undefined),
    order: _emberData.default.attr('number'),

    question: (0, _relationships.belongsTo)('question')
  });
});