define('student/models/v1/convention-provider-config', ['exports', 'vd-shared/models/v1/convention-provider-config'], function (exports, _conventionProviderConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conventionProviderConfig.default;
    }
  });
});