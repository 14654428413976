define('student/components/vd-disable-global-scroll', ['exports', 'vd-shared/components/vd-disable-global-scroll'], function (exports, _vdDisableGlobalScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vdDisableGlobalScroll.default;
    }
  });
});