define('student/instance-initializers/common-attrs', ['exports', 'vd-shared/instance-initializers/common-attrs'], function (exports, _commonAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _commonAttrs.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _commonAttrs.initialize;
    }
  });
});