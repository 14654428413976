define('student/mixins/not-found-redirection-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      error: function error(_error, transition) {
        if (Ember.get(_error, 'errors.firstObject.status') === '404') {
          return this.transitionTo('not-found', Ember.get(transition, 'params.institution-scope.subdomain'));
        }

        // Bubble the error if, not a 404 from server so it can be logged.
        return true;
      }
    }
  });
});