define('student/utils/sort-by-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = sortByTime;


  // Utility function that sorts items by Moment times, given the time attr to fetch.
  function sortByTime(sortAttr) {
    return function (a, b) {
      var startTimeA = Ember.get(a, sortAttr);
      var startTimeB = Ember.get(b, sortAttr);

      return moment(startTimeA).diff(moment(startTimeB));
    };
  }
});