define('student/models/question', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    audience: (0, _attr.default)('string'),
    charLimit: (0, _attr.default)('number'),
    order: (0, _attr.default)('number'),
    page: (0, _attr.default)('number'),
    questionType: (0, _attr.default)('string'),
    required: (0, _attr.default)('boolean'),
    status: (0, _attr.default)('string'),
    summaryDescription: (0, _attr.default)('string'),
    summaryName: (0, _attr.default)('string'),
    text: (0, _attr.default)('string'),

    institution: (0, _relationships.belongsTo)('institution'),
    questionOptions: (0, _relationships.hasMany)('questionOption', { async: false })
  });
});