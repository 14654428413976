define('student/models/v1/default-string', ['exports', 'vd-shared/models/v1/default-string'], function (exports, _defaultString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _defaultString.default;
    }
  });
});