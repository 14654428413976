define('student/components/student-user-visit-conference-drawer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    userVisit: undefined,
    institution: undefined,

    itinerary: Ember.computed.reads('userVisit.itinerary').readOnly(),
    visit: Ember.computed.reads('userVisit.visit').readOnly(),

    drawerIsOpen: true,
    hasDrawer: Ember.computed.or('visit.zoomAudioNumber', 'visit.integratedMeetingUrl', 'institution.randomSmsNumber')
  });
});