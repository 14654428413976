define('student/models/location', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    city: (0, _attr.default)('string'),
    distance: (0, _attr.default)('number'),
    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    meetingLocation: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    parkingLocation: (0, _attr.default)('string'),
    zipcodeDisplay: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    street: (0, _attr.default)('string'),
    street2: (0, _attr.default)('string'),
    street3: (0, _attr.default)('string')
  });
});