define('student/components/vd-form-field/component', ['exports', '@material/form-field', '@material/radio', '@material/checkbox'], function (exports, _formField, _radio, _checkbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['vd-form-field', 'mdc-form-field'],

    allowMultiple: false,
    checked: false,
    disabled: false,
    label: null,
    name: null,
    value: null,

    id: Ember.computed('name', 'value', function () {
      var id = Ember.get(this, 'name') + '-' + Ember.get(this, 'value');
      id = id.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      id = id.replace(/^-+/, '').replace(/-+$/, '');
      return 'vd-form-field-' + id;
    }),

    didInsertElement: function didInsertElement() {
      var allowMultiple = Ember.get(this, 'allowMultiple');

      var element = Ember.get(this, 'element');
      var formField = new _formField.MDCFormField(element);
      if (allowMultiple) {
        var checkbox = new _checkbox.MDCCheckbox(this.$('.mdc-checkbox')[0]);
        formField.input = checkbox;
      } else {
        var radio = new _radio.MDCRadio(this.$('.mdc-radio')[0]);
        formField.input = radio;
      }

      // Fix occasional bug with checked state not reflecting properly
      var checked = Ember.get(this, 'checked');
      if (checked) {
        var input = element.querySelector('input');
        if (!input.checked) {
          input.checked = true;
        }
      }

      this.mdcFormField = formField;
    }
  });
});