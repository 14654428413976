define('student/adapters/application', ['exports', 'ember-data/adapters/json-api', 'student/adapters/config', 'ember-inflector'], function (exports, _jsonApi, _config, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.serializeParams = serializeParams;
  function serializeParams(obj) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  } /* eslint-disable no-prototype-builtins, ember/new-module-imports */

  exports.default = _jsonApi.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var _AdapterConfig$create = _config.default.create(),
          host = _AdapterConfig$create.host,
          headers = _AdapterConfig$create.headers;

      Ember.setProperties(this, {
        host: host,
        headers: headers,
        namespace: 'student'
      });
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var subdomain = window.location.pathname.split('/')[1];
      Ember.setProperties(this, { namespace: 'student/' + subdomain });
      return this._super(store, type, id, snapshot);
    },
    queryRecord: function queryRecord(store, type, query) {
      var subdomain = window.location.pathname.split('/')[1];
      Ember.setProperties(this, { namespace: 'student/' + subdomain });
      return this._super(store, type, query);
    },

    pathForType: function pathForType(type) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(type));
    }
  });
});