define('student/mobi-instance/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var id = _ref.id;

      return Ember.RSVP.hash({ instanceId: id });
    }
  });
});