define('student/models/itinerary-accessibility', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    status: (0, _attr.default)('string'),
    accessibilityDetail: (0, _attr.default)('string'),
    accessibilityAcknowledgedAt: (0, _attr.default)('date'),

    visitor: (0, _relationships.belongsTo)('visitor', { polymorphic: true, async: true }),
    itinerary: (0, _relationships.belongsTo)('itinerary')
  });
});