define('student/components/vd-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['vd-header'],

    text: null
  });
});