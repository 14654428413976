define('student/services/api-service', ['exports', 'vd-shared/services/api-service'], function (exports, _apiService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _apiService.default;
    }
  });
});